import { Component, computed, inject, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

/**
 * Heavily leveraged code from:
 * https://dev.to/valorsoftware/google-translate-customization-under-nextjs-h69
 */
@Component({
  selector: 'pg-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['language-switcher.component.scss'],
  providers: [CookieService],
})
export class LanguageSwitcherComponent implements OnInit {
  cookieService = inject(CookieService);
  // The following cookie name is important because it's Google-predefined for the translation engine purpose
  ENGLISH_LOCALE: string = 'en';
  SPANISH_LOCALE: string = 'es';
  COOKIE_NAME: string = 'googtrans';
  SUPPORTED_LANGUAGES: string[] = [this.ENGLISH_LOCALE, this.SPANISH_LOCALE];

  currentLanguage: WritableSignal<string> = signal('en');
  isSpanish: Signal<boolean> = computed(() => this.currentLanguage() === this.SPANISH_LOCALE);
  languageToSwitchTo: Signal<string> = computed<string>(() => (this.isSpanish() ? this.ENGLISH_LOCALE : this.SPANISH_LOCALE));


  ngOnInit() {
    this.getCurrentLanguage();
  }

  getCurrentLanguage() {
    const cookie = document.cookie;
    const cookieValues = cookie.split(';')?.filter((c) => c.includes(this.COOKIE_NAME));
    const correctCookieValue = cookieValues?.find((c) => c.includes('/auto/'));
    if (correctCookieValue) {
      const lang = correctCookieValue.split('/').pop();
      if (lang && this.SUPPORTED_LANGUAGES.includes(lang)) {
        this.currentLanguage.set(lang);
      }
    }
  }

  switchLanguageTo() {
    // We just need to set the related cookie and reload the page
    // "/auto/" prefix is Google's definition as far as a cookie name
    this.currentLanguage.set(this.currentLanguage() === this.ENGLISH_LOCALE ? this.SPANISH_LOCALE : this.ENGLISH_LOCALE);

    this.deleteCookie(this.COOKIE_NAME, '/');

    this.setCookie(this.COOKIE_NAME, '/auto/' + this.currentLanguage());

    window.location.reload();
  }

  setCookie(cname: string, cvalue: string) {
    // this.deleteCookie(cname, '/');
    // Set the new cookie value
    document.cookie = `${cname}=${cvalue}; path=/;`;
  }

  deleteCookie(cname: string, path: string = '/') {
    this.cookieService.delete(cname, path, environment.DOMAIN_WILDCARD);
  }
}
