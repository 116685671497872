/* tslint:disable: ordered-imports*/
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Modules */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '@modules/icons/icons.module';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
/* Containers */
import * as appCommonContainers from './containers';

/* Components */
import * as appCommonComponents from './components';

/* Directives */
import * as appCommonDirectives from './directives';

/* Guards */
import * as appCommonGuards from './guards';

/* Services */
import * as appCommonServices from './services';
import * as authServices from '@modules/auth/services';

/* Pipes */
import * as appCommonPipes from './pipes';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbOptionModule } from 'mdb-angular-ui-kit/option';

const modules = [IconsModule, NgbModule, TourNgBootstrapModule, MdbNotificationModule];

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, ...modules, MdbTooltipModule, MdbModalModule, MdbOptionModule],
  declarations: [...appCommonContainers.containers, ...appCommonComponents.components, ...appCommonDirectives.directives, ...appCommonPipes.pipes],
  exports: [...appCommonContainers.containers, ...appCommonComponents.components, ...appCommonDirectives.directives, ...appCommonPipes.pipes, ...modules, MdbModalModule],
})
export class AppCommonModule {
  static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [...appCommonServices.services, ...authServices.services, ...appCommonGuards.guards, { provide: 'window', useValue: window }],
    };
  }
}
