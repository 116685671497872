import { Injectable } from '@angular/core';
import { AmplitudeAnalyticsService, LoginEvent } from '@modules/analytics/services';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { StateResetAll } from 'ngxs-reset-plugin';
import {
  GetMyselfGQL,
  IsAuthenticatedGQL,
  LoginGQL,
  LoginMutation,
  LogoutCurrentUserFromSciOpsGQL,
  LogoutGQL,
  TokenAuthenticationGQL,
  UpdateUserGQL,
  UpdateUserProfileGQL,
  UpdateUserTourGQL,
  UserRole,
  UserSciopsData,
} from '../../../generated/graphql';
import {
  LoginUser,
  LogoutUser,
  RefreshUserData,
  TokenLogin,
  UpdateUser,
  UpdateUserProfile,
  UpdateUserTour,
} from './loggedInUser.actions';
import { LanguageToolsService } from '@common/services/language-tools.service';
import { Characters } from '../enum/characters';
import { Router } from '@angular/router';

export type LoggedInUser = LoginMutation['login'];
export type LoggedInUserProfile = LoggedInUser['profile'];

export interface LoggedInUserStateModel {
  isLoggedIn: boolean;
  gameSave?: UserSciopsData;
  user?: LoggedInUser;
  error?: string;
}

@State<LoggedInUserStateModel>({
  name: 'loggedInUser',
  defaults: {
    user: undefined,
    gameSave: undefined,
    error: '',
    isLoggedIn: false,
  },
})
@Injectable()
export class LoggedInUserState {
  constructor(
    private store: Store,
    private loginService: LoginGQL,
    private logoutService: LogoutGQL,
    private updateUserService: UpdateUserGQL,
    private updateUserProfileService: UpdateUserProfileGQL,
    private updateUserTourService: UpdateUserTourGQL,
    private getMyselfGQL: GetMyselfGQL,
    private tokenAuthenticationGQL: TokenAuthenticationGQL,
    private isAuthenticatedGQL: IsAuthenticatedGQL,
    private apollo: Apollo,
    private router: Router,
    private readonly logoutCurrentUserFromSciOpsGQL: LogoutCurrentUserFromSciOpsGQL,
    private readonly amplitudeAnalyticsService: AmplitudeAnalyticsService,
    private readonly languageToolsService: LanguageToolsService
  ) {}

  @Selector()
  static LoginError(state: LoggedInUserStateModel) {
    return state.error || '';
  }

  @Selector()
  static UserEmail(state: LoggedInUserStateModel) {
    return state.user?.email || '';
  }

  @Selector()
  static UserCharacter(state: LoggedInUserStateModel) {
    const character = state.user?.profile.character || Characters.CHAR_01;
    return `assets/img/characters/full/${character}.png`;
  }

  @Selector()
  static UserCharacterAvatar(state: LoggedInUserStateModel) {
    if (state.user) {
      const role = state.user?.role;
      if (role == UserRole.Student) {
        const character = state.user?.profile.character || Characters.CHAR_01;
        return `assets/img/characters/avatar/${character}.png`;
      } else if ([UserRole.Teacher, UserRole.District, UserRole.Admin, UserRole.User].includes(role)) {
        return `https://dkmzd4tgwtjws.cloudfront.net/public/images/profile_pictures/plasma_logo_blue.png`;
      }
    }
  }

  @Selector()
  static getLoggedInUser(state: LoggedInUserStateModel): LoggedInUser | undefined {
    return state.user;
  }

  @Action(LoginUser)
  loginUser(ctx: StateContext<LoggedInUserStateModel>, action: LoginUser) {
    const state = ctx.getState();
    let loginAmplitudeEvent: LoginEvent = { loginSuccess: 'FAILED' };
    if (!state || !state.isLoggedIn) {
      this.store.dispatch(new StateResetAll());
      return this.loginService
        .mutate(
          {
            email: action.email,
            password: action.password,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(async ({ data }) => {
            if (typeof data?.login !== 'undefined') {
              const user: LoggedInUser = data?.login;
              ctx.setState({
                user,
                isLoggedIn: true,
              });

              const rolesMap = {
                [UserRole.Admin]: '/admin',
                [UserRole.Teacher]: '/teacher',
                [UserRole.Student]: '/student',
                [UserRole.District]: '/district',
                [UserRole.User]: '/',
              };

              const userRoleRoute = rolesMap[user.role as UserRole];
              const userLang = user.profile.language || 'en';
              const redirectUrl = action.redirectUrl;
              await this.setLangCookie(userLang);

              if (redirectUrl && redirectUrl?.length > 0 && redirectUrl.includes(userRoleRoute)) {
                const decoded = redirectUrl;
                await this.router.navigateByUrl(decoded);
                loginAmplitudeEvent.redirectUrl = decoded;
              } else if (user.role === UserRole.Admin) {
                this.store.dispatch(new Navigate(['/admin']));
                loginAmplitudeEvent.redirectUrl = '/admin';
              } else if (user.role === UserRole.Teacher) {
                this.store.dispatch(new Navigate(['/teacher']));
                loginAmplitudeEvent.redirectUrl = '/teacher';
              } else if (user.role === UserRole.District) {
                this.store.dispatch(new Navigate(['/district']));
                loginAmplitudeEvent.redirectUrl = '/district';
              } else if (user.role === UserRole.Student) {
                this.store.dispatch(new Navigate(['/student']));
                loginAmplitudeEvent.redirectUrl = '/student';
              } else {
                this.store.dispatch(new Navigate(['/']));
                loginAmplitudeEvent.redirectUrl = '/';
              }
              loginAmplitudeEvent.loginSuccess = 'SUCCEEDED';
              loginAmplitudeEvent.userRole = user.role;
            }
            this.amplitudeAnalyticsService.trackRegularLogin(loginAmplitudeEvent);
          })
        );
    }
  }

  async setLangCookie(lang: string) {
    this.languageToolsService.switchLanguageToSpecific(lang);
  }

  @Action(LogoutUser)
  async logoutUser(ctx: StateContext<LoggedInUserStateModel>, action: LogoutUser) {
    function buildErrorMessage(error?: string): string {
      if (error && error != 'Unauthorized') {
        return `Logged out because of the following: ${error}.  Please try logging in again.`;
      } else {
        return '';
      }
    }

    const state = ctx.getState();
    const maybeErrorMessage = buildErrorMessage(state?.error || action.error);

    if (state?.isLoggedIn) {
      return this.logoutService.mutate().pipe(
        tap(({ data }) => {
          if (data?.logout) {
            this.apollo.client.resetStore().then(() => {
              ctx.setState({ isLoggedIn: false, gameSave: undefined, user: undefined, error: state?.error });
              this.store.dispatch(new Navigate([`/auth/login`], { error: maybeErrorMessage }));
            });
          }
        })
      );
    } else {
      this.store.dispatch(new Navigate([`/auth/login`], { error: maybeErrorMessage }));
    }
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUser) {
    const state = ctx.getState();
    if (state?.isLoggedIn) {
      ctx.patchState({ error: '' });
      return this.updateUserService
        .mutate(
          {
            getUser: { _id: state.user?._id },
            updateUser: action.updateUser,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(({ data }) => {
            if (typeof data?.updateUser !== 'undefined') {
              const user: LoggedInUser = data?.updateUser;
              ctx.patchState({ user });
              if (action.route) {
                this.store.dispatch(new Navigate([action.route]));
              }
            }
          })
        );
    }
  }

  @Action(UpdateUserProfile)
  updateUserProfile(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUserProfile) {
    const state = ctx.getState();
    if (state?.isLoggedIn) {
      ctx.patchState({ error: '' });
      return this.updateUserProfileService
        .mutate(
          {
            getUser: { _id: state.user?._id },
            userProfile: action.userProfile,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(({ data }) => {
            if (typeof data?.updateUserProfile !== 'undefined') {
              const user: LoggedInUser = data.updateUserProfile;
              ctx.patchState({ user });
              if (action.route) {
                this.store.dispatch(new Navigate([action.route]));
              }
            }
          })
        );
    }
  }

  @Action(UpdateUserTour)
  updateUserTour(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUserTour) {
    const state = ctx.getState();
    if (state?.isLoggedIn) {
      ctx.patchState({ error: '' });
      return this.updateUserTourService
        .mutate(
          {
            getUser: { _id: state.user?._id },
            completedToursDto: action.completedToursDto,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(({ data }) => {
            if (typeof data?.updateUserTour !== 'undefined') {
              const user: LoggedInUser = data.updateUserTour;
              ctx.patchState({ user });
            }
          })
        );
    }
  }

  @Action(RefreshUserData)
  refreshUserData({ patchState, getState, setState }: StateContext<LoggedInUserStateModel>) {
    const userId = getState().user?._id;
    if (userId) {
      return this.getMyselfGQL.fetch({}, { fetchPolicy: 'no-cache' }).pipe(
        catchError((err) => {
          const error = err?.message;
          console?.error(`Error refreshing user data: ${JSON.stringify(error)}`);
          setState({
            isLoggedIn: false,
            error,
          });
          return of(error);
        }),
        tap(({ data }) => {
          const updatedUser = data?.getMe;
          patchState({
            user: updatedUser,
          });
        })
      );
    }
  }

  @Action(TokenLogin)
  tokenLogin(ctx: StateContext<LoggedInUserStateModel>, action: TokenLogin) {
    const state = ctx.getState();
    let userRole = UserRole.User;
    let loginAmplitudeEvent: LoginEvent = { loginSuccess: 'FAILED' };
    if (!state || !state.isLoggedIn) {
      this.store.dispatch(new StateResetAll());
      return this.tokenAuthenticationGQL.mutate({ token: action.token }, { fetchPolicy: 'no-cache' }).pipe(
        catchError((err) => {
          const error = err?.message;
          ctx.setState({
            isLoggedIn: false,
            error,
          });
          return of(error);
        }),
        tap(({ data }) => {
          if (typeof data?.tokenAuthentication !== 'undefined') {
            const user: LoggedInUser = data?.tokenAuthentication;
            ctx.setState({
              user,
              isLoggedIn: true,
            });

            userRole = user.role;

            if (user.role === UserRole.Admin) {
              this.store.dispatch(new Navigate(['/admin']));
              loginAmplitudeEvent.redirectUrl = '/admin';
            } else if (user.role === UserRole.Teacher) {
              this.store.dispatch(new Navigate(['/teacher']));
              loginAmplitudeEvent.redirectUrl = '/teacher';
            } else if (user.role === UserRole.District) {
              this.store.dispatch(new Navigate(['/district']));
              loginAmplitudeEvent.redirectUrl = '/district';
            } else if (user.role === UserRole.Student) {
              this.store.dispatch(new Navigate(['/student']));
              loginAmplitudeEvent.redirectUrl = '/student';
            } else if (user.role === UserRole.User) {
              this.store.dispatch(new Navigate(['/user']));
              loginAmplitudeEvent.redirectUrl = '/user';
            } else {
              this.store.dispatch(new Navigate(['/']));
              loginAmplitudeEvent.redirectUrl = '/';
            }
            loginAmplitudeEvent.loginSuccess = 'SUCCEEDED';
            loginAmplitudeEvent.userRole = userRole;
          }
          this.amplitudeAnalyticsService.trackSSOLogin(loginAmplitudeEvent);
        })
      );
    }
    this.store.dispatch(new Navigate(['/']));
  }
}
