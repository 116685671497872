import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as amplitude from '@amplitude/analytics-browser';

export type LoginEvent = {
  loginSuccess: 'SUCCEEDED' | 'FAILED';
  userRole?: string;
  redirectUrl?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AmplitudeAnalyticsService {
  private isLoaded: boolean = false;

  public initAmplitude() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      }

      if (this.missingAmplitudeId()) {
        console.warn("Not initializing Amplitude since there's no Amplitude ID specified for this environment");
        return resolve(false);
      }

      if (this.missingAmplitudeServerUrl()) {
        console.warn("Not initializing Amplitude since there's no Amplitude URL specified for this environment");
        return resolve(false);
      }

      amplitude.init(environment.AMPLITUDE_ID, {
        useBatch: false,
        serverUrl: environment.AMPLITUDE_URL,
        defaultTracking: false,
      });
      return resolve((this.isLoaded = true));
    });
  }

  public trackRegularLogin(loginEvent: LoginEvent) {
    amplitude.track('LOGIN CLICKED', loginEvent);
  }

  public trackSSOLogin(loginEvent: LoginEvent) {
    amplitude.track('LOGIN/SIGN UP WITH GOOGLE CLICKED', loginEvent);
  }

  private missingAmplitudeId(): boolean {
    return environment.AMPLITUDE_ID == null || environment.AMPLITUDE_ID?.length <= 0;
  }

  private missingAmplitudeServerUrl(): boolean {
    return environment.AMPLITUDE_URL == null || environment.AMPLITUDE_URL?.length <= 0;
  }
}
