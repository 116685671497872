import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { TeacherSignupService } from './teacher-signup.service';
import { CharactersService } from './characters.service';
import { RequestAccessService } from './request-access.service';
import { ForgotPasswordService } from './forgot-password.service';

export const services = [AuthService, UserService, TeacherSignupService, CharactersService, RequestAccessService, ForgotPasswordService];

export * from './auth.service';
export * from './user.service';
export * from './teacher-signup.service';
export * from './characters.service';
export * from './request-access.service';
export * from './forgot-password.service';
