import { AlertComponent } from './alert/alert.component';
import { AvatarComponent } from './avatar/avatar.component';
import { CardCollapsableComponent } from './card-collapsable/card-collapsable.component';
import { CardIconComponent } from './card-icon/card-icon.component';
import { CardImageComponent } from './card-image/card-image.component';
import { CardNavigationComponent } from './card-navigation/card-navigation.component';
import { CardViewDetailsComponent } from './card-view-details/card-view-details.component';
import { CardComponent } from './card/card.component';
import { DateRangeQuickComponent } from './date-range-quick/date-range-quick.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { LoremIpsumComponent } from './lorem-ipsum/lorem-ipsum.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { ProgresscardComponent } from './progresscard/progresscard.component';
import { StepComponent } from './step/step.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ToastComponent } from './toast/toast.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageSectionComponent } from './page-section/page-section.component';
import { CardAccordionFaqComponent } from './card-accordion-faq/card-accordion-faq.component';
import { BorderComponent } from './border/border.component';
import { CardIconLinkComponent } from './card-icon-link/card-icon-link.component';
import { CardIconLinkNavComponent } from './card-icon-link-nav/card-icon-link-nav.component';
import { SciopsModalComponent } from './sciops-modal/sciops-modal.component';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserRoleBadgeComponent } from './user-role-badge/user-role-badge.component';
import { UserStateBadgeComponent } from './user-state-badge/user-state-badge.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { PgToastComponent } from '@common/components/pg-toast/pg-toast.component';
import { TableIconButtonComponent } from './table-icon-button/table-icon-button.component';
import { LanguageSwitcherComponent } from '@common/components/language-switcher/language-switcher.component';
import { LanguageLoaderComponent } from '@common/components/language-loader/language-loader.component';

export const components = [
  AlertComponent,
  CardCollapsableComponent,
  CardComponent,
  CardIconComponent,
  CardImageComponent,
  CardNavigationComponent,
  CardViewDetailsComponent,
  DropdownComponent,
  LoremIpsumComponent,
  ProgressbarComponent,
  ProgresscardComponent,
  ToastComponent,
  AvatarComponent,
  DateRangeQuickComponent,
  StepComponent,
  TimelineComponent,
  PageHeaderComponent,
  PageSectionComponent,
  CardAccordionFaqComponent,
  BorderComponent,
  CardIconLinkComponent,
  CardIconLinkNavComponent,
  SciopsModalComponent,
  DeleteAccountModalComponent,
  ChangePasswordComponent,
  UserRoleBadgeComponent,
  UserStateBadgeComponent,
  UserResetPasswordComponent,
  PgToastComponent,
  TableIconButtonComponent,
  LanguageSwitcherComponent,
  LanguageLoaderComponent,
];

export * from './alert/alert.component';
export * from './card-collapsable/card-collapsable.component';
export * from './card-icon/card-icon.component';
export * from './card-image/card-image.component';
export * from './card-navigation/card-navigation.component';
export * from './card-view-details/card-view-details.component';
export * from './card/card.component';
export * from './dropdown/dropdown.component';
export * from './lorem-ipsum/lorem-ipsum.component';
export * from './progressbar/progressbar.component';
export * from './progresscard/progresscard.component';
export * from './toast/toast.component';
export * from './avatar/avatar.component';
export * from './date-range-quick/date-range-quick.component';
export * from './step/step.component';
export * from './timeline/timeline.component';
export * from './page-header/page-header.component';
export * from './page-section/page-section.component';
export * from './card-accordion-faq/card-accordion-faq.component';
export * from './border/border.component';
export * from './card-icon-link/card-icon-link.component';
export * from './card-icon-link-nav/card-icon-link-nav.component';
export * from './sciops-modal/sciops-modal.component';
export * from './delete-account-modal/delete-account-modal.component';
export * from './change-password/change-password.component';
export * from './user-role-badge/user-role-badge.component';
export * from './user-state-badge/user-state-badge.component';
export * from './user-reset-password/user-reset-password.component';
export * from './pg-toast/pg-toast.component';
export * from './table-icon-button/table-icon-button.component';
export * from './language-switcher/language-switcher.component';
export * from './language-loader/language-loader.component';
