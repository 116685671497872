<div class="form-switch form-check d-flex justify-content-between align-items-center ps-0">
  <div class="me-5 pe-1 text-white nav-link">ES</div>
  <input
    mdbCheckbox
    class="form-check-input"
    [value]="currentLanguage()"
    [checked]="currentLanguage() === ENGLISH_LOCALE"
    type="checkbox"
    (change)="switchLanguageTo()"
    data-pg-analytics-component-type="pg-global-nav-language-toggle"
    [attr.data-pg-analytics-object-name]="languageToSwitchTo()"
    [attr.data-pg-analytics-secondary-object-name]="currentLanguage()" />
  <div translate="no" class="ms-2 text-white nav-link">EN</div>
</div>
