import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageToolsService {
  cookieService = inject(CookieService);
  // The following cookie name is important because it's Google-predefined for the translation engine purpose
  ENGLISH_LOCALE: string = 'en';
  SPANISH_LOCALE: string = 'es';
  COOKIE_NAME: string = 'googtrans';
  SUPPORTED_LANGUAGES: string[] = [this.ENGLISH_LOCALE, this.SPANISH_LOCALE];

  currentLanguage: WritableSignal<string> = signal('en');
  isSpanish: Signal<boolean> = computed(() => this.currentLanguage() === this.SPANISH_LOCALE);
  languageToSwitchTo: Signal<string> = computed<string>(() => (this.isSpanish() ? this.ENGLISH_LOCALE : this.SPANISH_LOCALE));

  constructor() {}

  getCurrentLanguage() {
    const cookie = document.cookie;
    const cookieValues = cookie.split(';')?.filter((c) => c.includes(this.COOKIE_NAME));
    const correctCookieValue = cookieValues?.find((c) => c.includes('/auto/'));
    if (correctCookieValue) {
      const lang = correctCookieValue.split('/').pop();
      if (lang && this.SUPPORTED_LANGUAGES.includes(lang)) {
        this.currentLanguage.set(lang);
      }
    }
  }

  switchLanguageTo() {
    this.currentLanguage.set(this.currentLanguage() === this.ENGLISH_LOCALE ? this.SPANISH_LOCALE : this.ENGLISH_LOCALE);

    this.deleteCookie(this.COOKIE_NAME, '/');

    this.setCookie(this.COOKIE_NAME, '/auto/' + this.currentLanguage());

    window.location.reload();
  }

  switchLanguageToSpecific(language: string) {
    this.currentLanguage.set(language);

    this.deleteCookie(this.COOKIE_NAME, '/');

    this.setCookie(this.COOKIE_NAME, '/auto/' + this.currentLanguage());
  }

  setCookie(cname: string, cvalue: string) {
    document.cookie = `${cname}=${cvalue}; path=/;`;
  }

  deleteCookie(cname: string, path: string = '/') {
    this.cookieService.delete(cname, path, environment.DOMAIN_WILDCARD);
  }
}
